:root {
  --PlayerOverlay-animationDuration: 0.5s;
  --PlayerOverlay-iconDuration: 0.3s;
}
.mHHoICoh-I6SOtmM6slt8 {
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color-player-overlay);
  display: flex;
  align-items: center;
  justify-content: center;
}

._1hgAfcLRAYELjqXRmw_fzc {
  position: absolute;
  left: 44px;
  top: 30px;
  right: 50px;
  color: var(--color-player-default1);
  display: flex;
  align-items: center;
  height: 60px;
}

._3Xh0hRxSEoRYd4fNp5wqkd {
  width: 24px;
  height: 32px;
  transform: rotate(180deg);
  stroke: var(--color-player-default1);
  stroke-width: 2px;
  cursor: pointer;
  transition: stroke var(--PlayerOverlay-iconDuration) ease-in-out;
}

._3Xh0hRxSEoRYd4fNp5wqkd:hover {
  stroke: var(--color-player-highlight-hover1);
}

.cbuquvcbpXKDLFsoBFDz2 {
  width: 80px;
  height: 80px;
  display: inline-block;
  margin-left: 15px;
}

._20Rkzyd2dlZn2xL3sNt2g- {
  display: inline-block;
  margin-left: 20px;
  font-size: var(--fontsize-l);
}

._2E6iNvPbI9y-5Pj7BH2XnX {
  font-weight: bold;
  margin: 0;
}

._3oZYiFX3bqL4U0QqiDEVU2 {
  margin: 0;
}

._2E6iNvPbI9y-5Pj7BH2XnX + ._3oZYiFX3bqL4U0QqiDEVU2 {
  margin-top: 10px;
}

.QIKI5NBCZoPzuEys7z9dF {
  justify-content: center;
  display: flex;
  align-items: center;
}

._1cfAvyh9_YvZFCDg42JhJl {
  display: inline-block;
}

._3JgYyb4ysEpTTAzMTtWSrX > svg {
  cursor: pointer;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  fill: var(--color-icon-playlock-fill);
  stroke: var(--color-icon-playlock-stroke);
  transition: fill var(--PlayerOverlay-animationDuration) ease-in-out,
    stroke var(--PlayerOverlay-animationDuration) ease-in-out;
}

._3JgYyb4ysEpTTAzMTtWSrX circle {
  fill: var(--color-icon-playlock-circle-fill);
  fill-opacity: var(--color-icon-playlock-circle-fillOpacity);
  stroke-width: var(--color-icon-playlock-circle-strokeWidth);
}

._3JgYyb4ysEpTTAzMTtWSrX:hover {
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
}

._3rV1jW-3f_3Yet13ILNbnI {
  width: 60px;
  height: 60px;
  margin: 100px;
  cursor: pointer;
  display: inline-block;
  z-index: 10000;
  color: var(--color-player-default1);
  fill: var(--color-player-default1);
  stroke: var(--color-player-default1);
  stroke-width: 0.4;
  transition: fill var(--PlayerOverlay-animationDuration) ease-in-out,
    stroke var(--PlayerOverlay-animationDuration) ease-in-out;
}
._2edGbuLrNxNNWFm541tgv8 {
  width: 60px;
  height: 60px;
  margin: 100px;
  cursor: pointer;
  display: inline-block;
  z-index: 10000;
  color: var(--color-primary3);
  fill: var(--color-primary3);
  stroke: var(--color-primary3);
  stroke-width: 0.4;
  transition: fill var(--PlayerOverlay-animationDuration) ease-in-out,
    stroke var(--PlayerOverlay-animationDuration) ease-in-out;
}
._3rV1jW-3f_3Yet13ILNbnI:hover {
  fill: var(--color-player-highlight-hover1);
  stroke: var(--color-player-highlight-hover1);
}

._38NhfmShd_96BQpIMrLjH1 {
  background: var(--color-content-progressbar-buffer-empty2);
  position: absolute;
  width: calc(100% - 100px);
  bottom: 100px;
  left: 50px;
  right: 50px;
  height: 6px;
  border-radius: 6px;
}

._2oMVekLNld0BP5fx6YuCt4 {
  position: absolute;
  right: 50px;
  top: calc(50vh - 20px);
  height: 40px;
  width: 40px;
  cursor: pointer;
  fill: var(--color-player-default1);
  transition: fill var(--PlayerOverlay-iconDuration) ease-in-out;
}

._2oMVekLNld0BP5fx6YuCt4:hover {
  fill: var(--color-player-highlight-hover1);
}

.Qq1SMJGNn2tCSGt_C2vSr {
  position: absolute;
  bottom: 107px;
  color: var(--color-player-default1);
  right: 50px;
}
._36uAYvyYEyR5mxr9K3xEbx {
  top: var(--EPGGridText-textContainer-padding);
  right: var(--EPGGridText-textContainer-padding);
  fill: var(--color-primary1);
  width: 20px;
  height: 20px;
  cursor: pointer;
}
._3w1ts3T4rbUEErfoUwSrgd {
  color: var(--color-player-default1);
  border: none;
  background: transparent;
  position: absolute;
  bottom: 30px;
  right: 50px;
  display: flex;
}
._1A6tTrjseGn7pdDOQO63re {
  color: var(--color-player-default1);
  border: none;
  background: transparent;
  position: absolute;
  bottom: 30px;
  left: 50px;
  display: flex;
}

.qwDCQ9Td1Ok7ssdC-44h3 {
  position: absolute;
  left: 86px;
  bottom: 22px;
}

.qwDCQ9Td1Ok7ssdC-44h3 details > div {
  right: unset;
}

._3w1ts3T4rbUEErfoUwSrgd > * {
  margin: 0 20px;
}

.IQrLlK66y_JucPwNuVUOz {
  cursor: pointer;
  fill: var(--color-player-default1);
  stroke: var(--color-player-default1);
  transition: fill var(--PlayerOverlay-iconDuration) ease-in-out,
    stroke var(--PlayerOverlay-iconDuration) ease-in-out;
}

.IQrLlK66y_JucPwNuVUOz:hover {
  fill: var(--color-player-highlight-hover1);
  stroke: var(--color-player-highlight-hover1);
}

.ph6foUeswQlD4OEfwuHsJ {
  position: absolute;
  bottom: 200px;
  right: 40px;
  width: 400px;
  height: 110px;
  background: var(--color-content-overlay1);
  z-index: 2000;
  padding: 10px;
  box-sizing: border-box;
}

._3HlzPlFkLBo_sqAIgd6MTt {
  height: 90px;
  width: 160px;
  margin-right: 10px;
  display: inline-block;
}

.sndy9Rnb261j_fZLj5TSo {
  width: 210px;
  height: 90px;
  position: absolute;
  display: inline-flex;
  right: 10px;
  align-items: center;
}

._1xEf81hoUcqVZR3awvWXYV {
  width: 174px;
}

._30OusYGlXjxIbpeq1nIcr0 {
  font-size: var(--fontSize-s);
  color: var(--color-player-default2);
  margin-bottom: 5px;
}
.tGLHl8YveYhz3ujWMsqmX {
  margin-top: 5px;
  font-size: var(--fontSize-m);
  font-weight: bold;
  color: var(--color-player-default1);
  max-width: 180px;
  text-overflow: ellipsis;
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 3.6em;
}

/* hide ... if we have text, which is less than or equal to max lines */
.G0qIjrA0Rm_pMf0mtjiAV:after {
  /* points in the end */
  content: "";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}

._3OtF2oQwIJ1dy2cO01JwyV {
  position: absolute;
  right: 5px;
  top: 20px;
  width: 24px;
  height: 24px;
  fill: var(--color-player-default1);
  cursor: pointer;
  transition: fill var(--PlayerOverlay-iconDuration) ease-in-out;
}

._3OtF2oQwIJ1dy2cO01JwyV:hover {
  fill: var(--color-player-highlight-hover1);
}

._36o5KzlNuM6Z9417EB10l0 {
  position: absolute;
  top: 20px;
  right: 100px;
  width: 200px;
  background: white;
  color: black;
  z-index: 1001;
}

/* Overlay aninmation */
._3DkSRMzvOSXjnImgG--2jV {
  opacity: 0;
}

._1c9ugD8BmtWLaxF5HRmqtg {
  opacity: 0;
}

._1_LMJz1Xiy1XEFDd9zM-9c {
  transition: opacity var(--PlayerOverlay-animationDuration) ease-in-out;
  opacity: 1;
}

.JT6GbKRQE_JNOZDO8gga0 {
  opacity: 1;
}

.ZvkuRs3ztORTeBNgcb1hP {
  opacity: 1;
}

._1rZ5MukQbqKeMFe_U2PIX6 {
  opacity: 0;
  transition: opacity var(--PlayerOverlay-animationDuration) ease-in-out;
}

._3WHcj_P9NhEkFySNH6i9xW {
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 1023px) {
  ._3rV1jW-3f_3Yet13ILNbnI {
    margin: 50px;
    height: 50px;
    width: 50px;
  }

  ._3JgYyb4ysEpTTAzMTtWSrX > svg {
    height: 80px;
    width: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Qq1SMJGNn2tCSGt_C2vSr {
    bottom: 160px;
  }

  ._38NhfmShd_96BQpIMrLjH1 {
    bottom: 150px;
  }

  ._3w1ts3T4rbUEErfoUwSrgd,
  ._1A6tTrjseGn7pdDOQO63re {
    bottom: 80px;
  }
}

@media (max-width: 767px) {
  ._1hgAfcLRAYELjqXRmw_fzc {
    left: 15px;
    top: 5px;
    display: inline-flex;
  }
  .cbuquvcbpXKDLFsoBFDz2 {
    margin-left: 10px;
  }

  ._3Xh0hRxSEoRYd4fNp5wqkd {
    height: 20px;
    width: 13px;
  }

  ._20Rkzyd2dlZn2xL3sNt2g- {
    margin-left: 10px;
  }

  ._2E6iNvPbI9y-5Pj7BH2XnX {
    font-size: var(--fontSize-s);
  }

  ._3oZYiFX3bqL4U0QqiDEVU2,
  ._2RlZuducQLDCXhXcIfT-TI {
    font-size: var(--fontSize-xs);
  }

  ._3oZYiFX3bqL4U0QqiDEVU2 {
    margin: 0;
  }

  ._3rV1jW-3f_3Yet13ILNbnI {
    margin: 20px;
    height: 40px;
    width: 40px;
  }

  ._3JgYyb4ysEpTTAzMTtWSrX > svg {
    height: 60px;
    width: 60px;
  }

  ._2oMVekLNld0BP5fx6YuCt4 {
    height: 25px;
    width: 25px;
  }

  .Qq1SMJGNn2tCSGt_C2vSr {
    font-size: var(--fontSize-xs);
  }

  ._38NhfmShd_96BQpIMrLjH1 {
    width: calc(100% - 20px);
    left: 10px;
    right: 10px;
    bottom: 120px;
  }

  .ph6foUeswQlD4OEfwuHsJ {
    width: 340px;
    height: 72px;
    bottom: 20%;
    left: 10px;
  }

  ._3HlzPlFkLBo_sqAIgd6MTt {
    width: 92px;
    height: 52px;
    margin-right: 5px;
  }

  .sndy9Rnb261j_fZLj5TSo {
    width: 208px;
    height: 52px;
    right: 18px;
  }

  .tGLHl8YveYhz3ujWMsqmX {
    font-size: var(--fontSize-s);
    white-space: nowrap;
  }

  ._30OusYGlXjxIbpeq1nIcr0 {
    font-size: var(--fontSize-xs);
  }

  ._3OtF2oQwIJ1dy2cO01JwyV {
    top: 13px;
    right: 0;
  }

  ._3w1ts3T4rbUEErfoUwSrgd {
    right: 10px;
  }

  ._3w1ts3T4rbUEErfoUwSrgd,
  ._1A6tTrjseGn7pdDOQO63re {
    bottom: 80px;
  }

  ._3w1ts3T4rbUEErfoUwSrgd > * {
    margin: 0 10px;
  }
  .qwDCQ9Td1Ok7ssdC-44h3 {
    left: 86px;
    bottom: -10px;
  }
}

@media (max-width: 359px) {
  .ph6foUeswQlD4OEfwuHsJ {
    bottom: 15%;
  }
}
